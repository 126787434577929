import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // Ícone do PIX
import ScheduleIcon from '@mui/icons-material/Schedule'; // Ícone de agendar
import DescriptionIcon from '@mui/icons-material/Description'; // Ícone de contrato
import CallEndIcon from '@mui/icons-material/CallEnd'; // Ícone de finalizar atendimento
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'; // Ícone de transferência
import ButtonWithSpinner from "../ButtonWithSpinner";
import { AuthContext } from "../../context/Auth/AuthContext";
import FinanceiroTicketModal from "../FinanceiroTicketModal";
import ContratoTicketModal from "../ContratoTicketModal";
import AgendamentoTicketModal from "../AgendamentoTicketModal"; // Atualize o caminho
import TransferTicketModal from "../TransferTicketModal"; // Importa o modal de transferência
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
    actionButtons: {
        marginRight: 6,
        flex: "none",
        alignSelf: "center",
        marginLeft: "auto",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
}));

const TicketActionButtons = ({ ticket, handleClose, isMounted }) => {
    const [financeiroTicketModalOpen, setFinanceiroTicketModalOpen] = useState(false);
    const [contratoTicketModalOpen, setContratoTicketModalOpen] = useState(false); // Estado do modal de contrato
    const [agendamentoTicketModalOpen, setAgendamentoTicketModalOpen] = useState(false); // Estado para o novo modal
    const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false); // Estado para o modal de transferência

    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AuthContext);

    const handleUpdateTicketStatus = async (e, status, userId) => {
        setLoading(true);
        try {
            await api.put(`/tickets/${ticket.id}`, {
                status: status,
                userId: userId || null,
            });

            setLoading(false);
            if (status === "open") {
                history.push(`/tickets/${ticket.id}`);
            } else {
                history.push("/tickets");
            }
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    };

    // Função para abrir modal de contrato
    const handleOpenContratoTicketModal = () => {
        setContratoTicketModalOpen(true);
    };

    // Função para fechar modal de contrato
    const handleCloseContratoTicketModal = () => {
        setContratoTicketModalOpen(false);
    };

    return (
        <div className={classes.actionButtons}>
            {ticket.status === "open" && !ticket.isGroup && (
                <>
                    <Tooltip title="Gerar PIX" arrow>
                        <span>
                            <ButtonWithSpinner
                                loading={loading}
                                size="small"
                                variant="contained"
                                color="info"
                                onClick={() => setFinanceiroTicketModalOpen(true)}
                                sx={{
                                    minWidth: 0,
                                    width: '27px',
                                    height: '27px',
                                    padding: '0px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <MonetizationOnIcon fontSize="small" /> {/* Ícone do PIX */}
                            </ButtonWithSpinner>
                        </span>
                    </Tooltip>

                    <Tooltip title="Agendar Mensagem" arrow>
                        <span>
                            <ButtonWithSpinner
                                loading={loading}
                                size="small"
                                variant="contained"
                                color="info"
                                onClick={() => setAgendamentoTicketModalOpen(true)} // Abre o modal de agendamento
                                sx={{
                                    minWidth: 0,
                                    width: '27px',
                                    height: '27px',
                                    padding: '0px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <ScheduleIcon fontSize="small" />
                            </ButtonWithSpinner>
                        </span>
                    </Tooltip>

                    <Tooltip title="Gerar Contrato" arrow>
                        <span>
                            <ButtonWithSpinner
                                loading={loading}
                                size="small"
                                variant="contained"
                                color="info"
                                onClick={handleOpenContratoTicketModal} // Abre o modal de contrato
                                sx={{
                                    minWidth: 0,
                                    width: '27px',
                                    height: '27px',
                                    padding: '0px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <DescriptionIcon fontSize="small" /> {/* Ícone de contrato */}
                            </ButtonWithSpinner>
                        </span>
                    </Tooltip>

                    <Tooltip title="Finalizar Atendimento" arrow>
                        <span>
                            <ButtonWithSpinner
                                loading={loading}
                                size="small"
                                variant="contained"
                                color="info"
                                onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
                                sx={{
                                    minWidth: 0,
                                    width: '27px',
                                    height: '27px',
                                    padding: '0px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CallEndIcon fontSize="small" />
                            </ButtonWithSpinner>
                        </span>
                    </Tooltip>

                    <Tooltip title="Transferir Atendimento" arrow>
                        <span>
                            <ButtonWithSpinner
                                loading={loading}
                                size="small"
                                variant="contained"
                                color="info"
                                onClick={() => setTransferTicketModalOpen(true)} // Abre o modal de transferência
                                sx={{
                                    minWidth: 0,
                                    width: '27px',
                                    height: '27px',
                                    padding: '0px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <SwapHorizIcon fontSize="small" /> {/* Ícone de transferência */}
                            </ButtonWithSpinner>
                        </span>
                    </Tooltip>

                    {/* Modais */}
                    <AgendamentoTicketModal
                        modalOpen={agendamentoTicketModalOpen}
                        onClose={() => setAgendamentoTicketModalOpen(false)}
                        ticketid={ticket.id}
                    />
                    <FinanceiroTicketModal
                        modalOpen={financeiroTicketModalOpen}
                        onClose={() => setFinanceiroTicketModalOpen(false)}
                        ticketid={ticket.id}
                    />
                    <ContratoTicketModal
                        modalOpen={contratoTicketModalOpen} // Verifica se o modal de contrato está aberto
                        onClose={handleCloseContratoTicketModal} // Função para fechar o modal de contrato
                        ticketid={ticket.id}
                    />
                    <TransferTicketModal
                        modalOpen={transferTicketModalOpen}
                        onClose={() => setTransferTicketModalOpen(false)}
                        ticketid={ticket.id}
                    />
                </>
            )}
        </div>
    );
};

export default TicketActionButtons;
